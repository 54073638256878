import React, {Component} from "react"
import Layout from "../../../../components/layout"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import MoreNewsPage from '../MoreNews'
import { Helmet } from "react-helmet"
import { OutboundLink } from 'gatsby-plugin-gtag'

class ListNews extends Component {
  render() {
    const { data } = this.props;
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout pageInfo={{ pageName: "News" }} sitePage="site-page">
        <Helmet defer={false}>
            <title>News</title>
            <meta name="description" content="Stay updated with Open Techiz news" />
        </Helmet>
        <div className="news-page">
          <div className="wrapper-news">
            <div className="wrapper-featured">
              <h1 className="title-news">Stay updated with Open Techiz news</h1>
              <ul>
                {data.allStrapiNews.edges.map(document => (
                  <li key={document.node.id} className="box-new">
                    <OutboundLink href={`/${document.node.url}`}>
                      <Img fixed={document.node.image_header.childImageSharp.fixed} alt="900x500" className="image-item-blog lazyload"/>
                    </OutboundLink>
                    <h2>
                      <OutboundLink href={`/${document.node.url}`} className="name-item-news">{document.node.title}</OutboundLink>
                    </h2>
                    <div className="bottom-box-news">
                      <p>{document.node.updated_at}</p>
                      <p>{document.node.author.username}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <MoreNewsPage />
          </div>

          <div className="pagination">
            {!isFirst && (
              <OutboundLink href={`news/${prevPage}`} rel="prev" className="arrow-pagination">
                ←
              </OutboundLink>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
              >
                <OutboundLink
                  href={`/news/${i === 0 ? '' : i + 1}`}
                  className="number-pagination"
                  style={{
                    textDecoration: 'none',
                    color: i + 1 === currentPage ? '#ffffff' : '',
                    background: i + 1 === currentPage ? '#f7ab3b' : '',
                  }}
                >
                  {i + 1}
                </OutboundLink>
              </li>
            ))}
            {!isLast && (
              <OutboundLink href={`news/${nextPage}`} rel="next" className="arrow-pagination">
                →
              </OutboundLink>
            )}
          </div>
        </div>
      </Layout>
    )
}
}

export default ListNews

export const pageQuery = graphql`
  query ListNewsQuery($skip: Int, $limit: Int) {
    allStrapiNews (
      filter: {featured: {eq: 1}}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          image_header {
            childImageSharp {
              fixed(width: 2000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          title
          url
          updated_at(formatString: "MMMM Do YYYY")
          author {
            username
          }
        }
      }
    }
  }
`
