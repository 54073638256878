import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-gtag'

const MoreNewsPage = () => {
  const data = useStaticQuery(graphql`
    query MoreNewsQuery {
      allStrapiNews (
        sort: { order: DESC, fields: [updated_at] }
        limit: 3
      ) {
        edges {
          node {
            id
            image_header {
              childImageSharp {
                fixed(width: 2000) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            url
            updated_at(formatString: "MMMM Do YYYY")
            author {
              username
            }
          }
        }
      }
    }
  `)

  return (
    <div className="wrapper-more-news">
      <p className="title-news">More News</p>
      <ul>
        {data.allStrapiNews.edges.map(document => (
          <li key={document.node.id} className="box-new">
            <OutboundLink href={`/${document.node.url}`}>
              <Img fixed={document.node.image_header.childImageSharp.fixed} alt="900x500" className="image-item-blog lazyload"/>
            </OutboundLink>
            <h2>
              <OutboundLink href={`/${document.node.url}`}  className="name-item-news">{document.node.title}</OutboundLink>
            </h2>
            <div className="bottom-box-news">
              <p>{document.node.updated_at}</p>
              <p>{document.node.author.username}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MoreNewsPage
